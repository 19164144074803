import { RouteArrayType } from 'types/global-types'
import BacentaLeader from './HomePage/BacentaLeader'
import CouncilLeader from './HomePage/CouncilLeader'
import FellowshipLeader from './HomePage/FellowshipLeader'
import BacentaMembers from './membership/BacentaMembers'
import GovernorshipMembers from './membership/GovernorshipMembers'
import CouncilMembers from './membership/CouncilMembers'
import FellowshipMembers from './membership/FellowshipMembers'
import ProfilePage from './membership/MemberDetails'
import { membershipRoutes } from './membership/membershipRoutes'
import LandingPage from './ProfileChoosePage'
import GovernorshipLeader from './HomePage/GovernorshipLeader'

export const directory: RouteArrayType = [
  ...membershipRoutes,
  {
    path: '/profile-choose-page',
    element: LandingPage,
    roles: ['all'],
  },

  {
    path: '/fellowship/member-list',
    element: FellowshipMembers,
    roles: ['leaderFellowship'],
  },
  {
    path: '/bacenta/member-list',
    element: BacentaMembers,
    roles: ['leaderBacenta'],
  },
  {
    path: '/governorship/member-list',
    element: GovernorshipMembers,
    roles: ['leaderGovernorship'],
  },
  {
    path: '/council/member-list',
    element: CouncilMembers,
    roles: ['leaderCouncil'],
  },

  {
    path: '/fellowship/dashboard',
    element: FellowshipLeader,
    roles: ['leaderFellowship'],
  },
  {
    path: '/bacenta/dashboard',
    element: BacentaLeader,
    roles: ['leaderBacenta'],
  },
  {
    path: '/governorship/dashboard',
    element: GovernorshipLeader,
    roles: ['leaderGovernorship'],
  },
  {
    path: '/council/dashboard',
    element: CouncilLeader,
    roles: ['leaderCouncil'],
  },
  {
    path: '/member-details',
    element: ProfilePage,
    roles: ['all'],
  },
]
