import { useMutation, useQuery } from '@apollo/client'
import ApolloWrapper from 'components/ApolloWrapper/ApolloWrapper'
import { ChurchContext } from 'context/ChurchContext'
import { useContext } from 'react'
import { RECORD_MEMBER_BACENTA_ATTENDANCE } from './ticker.gql'
import AttendanceTicker from './AttendanceTicker'
import { GET_BACENTA_MEMBERS_FOR_BUSSING } from 'pages/directory/membership/memberList.gql'

const BussingAttendanceTicker = () => {
  const { bacentaId, bussingRecordId } = useContext(ChurchContext)

  const apollo = useQuery(GET_BACENTA_MEMBERS_FOR_BUSSING, {
    variables: { id: bacentaId, bussingRecordId },
  })
  const [RecordMemberAttendance] = useMutation(RECORD_MEMBER_BACENTA_ATTENDANCE)

  const bacenta = apollo.data?.bacentas[0]
  const bussing = apollo.data?.bussingRecords[0]

  return (
    <ApolloWrapper apolloResponse={apollo}>
      <AttendanceTicker
        church={bacenta}
        service={bussing}
        RecordMemberAttendance={RecordMemberAttendance}
      />
    </ApolloWrapper>
  )
}

export default BussingAttendanceTicker
