import { Box, Button, Center, Progress, VStack } from '@chakra-ui/react'
import useCustomColor from 'hooks/useCustomColor'
import LoginImage from 'login/Logo'
import React from 'react'

const LoggingYouIn = () => {
  const { brand } = useCustomColor()

  return (
    <Center height="80vh">
      <VStack>
        <Box>
          <LoginImage />
        </Box>
        <Box>
          <Progress marginY={5} size="xs" isIndeterminate />
          <Button
            background={brand}
            size="lg"
            width="336.92px"
            height="60px"
            //Loading Stuff
            disabled
          >
            Looging You In...
          </Button>
        </Box>
      </VStack>
    </Center>
  )
}

export default LoggingYouIn
