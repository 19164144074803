import { RouteArrayType } from 'types/global-types'
import FellowshipIMCLList from './FellowshipIMCLList'

export const imcl: RouteArrayType = [
  {
    path: '/fellowship/missing-persons',
    element: FellowshipIMCLList,
    roles: ['leaderFellowship'],
  },
]
