import { Grid, Button, Container } from '@chakra-ui/react'

import { useAuth0 } from '@auth0/auth0-react'
import { memberRole } from 'types/member-types'
import { useNavigate } from 'react-router-dom'
import GridButton from 'components/CustomGridItem'
import HeaderText from 'components/HeaderText'
import useUser from 'hooks/useUser'
import { useQuery } from '@apollo/client'
import { GET_USER_ROLES } from 'queries/user-roles.gql'
import ApolloWrapper from 'components/ApolloWrapper/ApolloWrapper'
import { useContext } from 'react'
import { ChurchContext } from 'context/ChurchContext'
import FellowshipLeaderImg from 'assets/img/profile-choose/fellowship-leader.jpg'
import BacentaLeaderImg from 'assets/img/profile-choose/bacenta-leader.jpg'
import GovernorshipLeaderImg from 'assets/img/profile-choose/governorship-leader.jpg'
import CouncilLeaderImg from 'assets/img/profile-choose/council-leader.jpeg'
import CampusLeaderImg from 'assets/img/profile-choose/campus-leader.jpg'
import CampusAdminImg from 'assets/img/profile-choose/campus-admin.jpg'
import useCustomColor from 'hooks/useCustomColor'

const ProfileChoosePage = () => {
  let navigate = useNavigate()
  const { setUser } = useUser()
  const { clickCard } = useContext(ChurchContext)
  const { user, logout } = useAuth0()
  const { data, loading, error } = useQuery(GET_USER_ROLES, {
    variables: {
      id: user?.sub || '',
    },
    onCompleted: (data) => {
      setUser(data?.members[0])
    },
  })
  const { brand } = useCustomColor()
  const loggedInUser = data?.members[0]

  return (
    <ApolloWrapper apolloResponse={{ data, loading, error }}>
      <Container>
        <HeaderText />
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {loggedInUser?.leadsFellowship.map(
            (fellowship: memberRole, i: number): JSX.Element => (
              <GridButton
                key={i}
                src={FellowshipLeaderImg}
                onClick={() => {
                  clickCard(fellowship)
                  navigate('/fellowship/dashboard')
                }}
                roleName="Fellowship Leader"
                roleLocation={fellowship.name}
              />
            )
          )}
          {loggedInUser?.leadsBacenta.map(
            (bacenta: memberRole, i: number): JSX.Element => (
              <GridButton
                key={i}
                src={BacentaLeaderImg}
                onClick={() => {
                  clickCard(bacenta)
                  navigate('/bacenta/dashboard')
                }}
                roleName="Bacenta Leader"
                roleLocation={bacenta.name}
              />
            )
          )}
          {loggedInUser?.leadsGovernorship.map(
            (governorship: memberRole, i: number): JSX.Element => (
              <GridButton
                key={i}
                src={GovernorshipLeaderImg}
                onClick={() => {
                  clickCard(governorship)
                  navigate('/governorship/dashboard')
                }}
                roleName="Governorship Overseer"
                roleLocation={governorship.name}
              />
            )
          )}
          {loggedInUser?.leadsCouncil.map(
            (council: memberRole, i: number): JSX.Element => (
              <GridButton
                key={i}
                src={CouncilLeaderImg}
                onClick={() => {
                  clickCard(council)
                  navigate('/council/dashboard')
                }}
                roleName="Bishop"
                roleLocation={council.name}
              />
            )
          )}
          {loggedInUser?.leadsCampus.map(
            (campus: memberRole, i: number): JSX.Element => (
              <GridButton
                key={i}
                src={CampusLeaderImg}
                onClick={() => {
                  clickCard(campus)
                  navigate('campus-service/dashboard')
                }}
                roleName="Resident Bishop"
                roleLocation={campus.name}
              />
            )
          )}
          {loggedInUser?.isAdminForCampus.map(
            (campus: memberRole, i: number): JSX.Element => (
              <GridButton
                key={i}
                src={CampusAdminImg}
                onClick={() => navigate('/admin/dashboard')}
                roleName="Super Admin"
                roleLocation={campus.name}
              />
            )
          )}
        </Grid>

        <Button
          width="97%"
          background={brand}
          color="white"
          marginTop="40px"
          paddingY={5}
          onClick={() => logout()}
        >
          Log Out
        </Button>
      </Container>
    </ApolloWrapper>
  )
}

export default ProfileChoosePage
