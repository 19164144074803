import { gql } from '@apollo/client'

export const GET_FELLOWSHIP_IMCL_LIST = gql`
  query fellowshipIMCL($id: ID!) {
    fellowships(where: { id: $id }) {
      id
      name
      imcls {
        id
        firstName
        lastName
        phoneNumber
        whatsappNumber
      }
    }
  }
`
