import { useQuery } from '@apollo/client'
import ApolloWrapper from 'components/ApolloWrapper/ApolloWrapper'
import { ChurchContext } from 'context/ChurchContext'
import { useContext } from 'react'
import { Bacenta } from 'types/global-types'
import ServicesList from './ServicesList'
import { DISPLAY_BACENTA_SERVICES } from './ticker/ticker.gql'

const BacentaServicesList = () => {
  const { bacentaId } = useContext(ChurchContext)

  const apolloResponse = useQuery(DISPLAY_BACENTA_SERVICES, {
    variables: { id: bacentaId },
  })

  const bacenta: Bacenta = apolloResponse.data?.bacentas[0]
  const services = bacenta?.services

  return (
    <ApolloWrapper apolloResponse={apolloResponse}>
      <ServicesList church={bacenta} services={services} />
    </ApolloWrapper>
  )
}

export default BacentaServicesList
