import { gql } from '@apollo/client'

export const DISPLAY_BACENTA_SERVICES = gql`
  query DisplayBacentaServices($id: ID!) {
    bacentas(where: { id: $id }) {
      id
      name
      services(limit: 100) {
        id
        attendance

        markedAttendance(churchId: $id)
        serviceDate {
          date
        }
      }
    }
  }
`

export const DISPLAY_BACENTA_BUSSING = gql`
  query getSundayBussing($id: ID!) {
    bacentas(where: { id: $id }) {
      id
      typename
      name
      bussing(limit: 100) {
        id
        typename
        attendance
        markedAttendance(churchId: $id)
        serviceDate {
          date
        }
      }
    }
  }
`

export const RECORD_MEMBER_FELLOWSHIP_ATTENDANCE = gql`
  mutation RecordMembershipAttendance(
    $bacentaId: ID!
    $presentMembers: [ID!]!
    $absentMembers: [ID!]!
    $recordId: ID!
  ) {
    RecordMembershipAttendance(
      bacentaId: $bacentaId
      presentMembers: $presentMembers
      absentMembers: $absentMembers
      recordId: $recordId
    ) {
      id
      markedAttendance(churchId: $bacentaId)
      membersPresent {
        id
        firstName
        lastName
        pictureUrl
      }
      membersAbsent {
        id
        firstName
        lastName
        pictureUrl
      }
    }
  }
`

export const RECORD_MEMBER_BACENTA_ATTENDANCE = gql`
  mutation RecordMemberBussingAttendance(
    $bacentaId: ID!
    $presentMembers: [ID!]!
    $absentMembers: [ID!]!
    $recordId: ID!
  ) {
    RecordMembershipAttendance(
      bacentaId: $bacentaId
      presentMembers: $presentMembers
      absentMembers: $absentMembers
      recordId: $recordId
    ) {
      id
      markedAttendance(churchId: $bacentaId)
      membersPresent {
        id
        firstName
        lastName
        pictureUrl
      }
      membersAbsent {
        id
        firstName
        lastName
        pictureUrl
      }
    }
  }
`

export const COMPUTE_MEMBERS_STATUSES = gql`
  mutation ComputeMembersStatuses($memberIds: [ID!]!) {
    ComputeMembersStatuses(members: $memberIds) {
      __typename
      id
      firstName
      lastName
      fullName
    }
  }
`
