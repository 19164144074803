import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, Container, Flex, Heading, Spacer, Text } from '@chakra-ui/react'
import Notice from 'components/Notice'
import { ChurchContext } from 'context/ChurchContext'
import useCustomColor from 'hooks/useCustomColor'
import { parseDate } from 'jd-date-utils'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { Bacenta, BussingRecord, ServiceRecord } from 'types/global-types'

type ServicesListProps = {
  services: ServiceRecord[] | BussingRecord[]
  church: Bacenta
}

const ServicesList = ({ services, church }: ServicesListProps) => {
  const { clickCard } = useContext(ChurchContext)
  const { bg } = useCustomColor()
  const navigate = useNavigate()

  return (
    <Container marginTop={10}>
      <Heading marginBottom={10}>
        {`${church?.name} ${church?.__typename}`}
      </Heading>
      {services?.map((service) => (
        <Flex
          key={service.id}
          alignContent="center"
          borderRadius="md"
          boxShadow="md"
          background={bg}
          padding={5}
          marginBottom={5}
          onClick={() => {
            clickCard(service)

            if (service.markedAttendance) {
              navigate(`/${church.__typename.toLowerCase()}/attendance-report`)
            } else {
              if (service.__typename === 'BussingRecord') {
                navigate(
                  `/${church.__typename.toLowerCase()}/tick-bussing-attendance`
                )
              } else {
                navigate(`/${church.__typename.toLowerCase()}/tick-attendance`)
              }
            }
          }}
        >
          <Box>
            <Text fontWeight="semibold">
              {parseDate(service.serviceDate.date)}
            </Text>
            <Text
              fontSize="xl"
              fontWeight="bold"
            >{`Attendance: ${service.attendance}`}</Text>
          </Box>
          <Spacer />
          {service.markedAttendance && (
            <Box marginRight={5} marginTop={5} alignSelf="center">
              <CheckCircleIcon
                fontSize={25}
                color="whatsapp.500"
                boxShadow="lg"
              />
            </Box>
          )}
        </Flex>
      ))}
      {!services?.length && (
        <Notice>
          <Text>No Records Found</Text>
        </Notice>
      )}
    </Container>
  )
}

export default ServicesList
