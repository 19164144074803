import { useColorModeValue } from '@chakra-ui/react'

const useCustomColor = () => {
  const bg = useColorModeValue('light.bg', 'dark.bg')
  const brand = useColorModeValue('light.brand.purple', 'dark.brand.purple')

  const text = {
    logo: useColorModeValue('#2A2A2A', '#D6D6D6'),
    secondary: useColorModeValue('light.text.secondary', 'dark.text.secondary'),
  }

  return { bg, brand, text }
}

export default useCustomColor
