import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const colors = {
  light: {
    text: {
      secondary: '#2A2A2A',
    },
    brand: {
      purple: '#611ABB',
      red: '#a53c35',
    },
    bg: '#e6e9ef',
  },
  dark: {
    text: {
      secondary: '#D6D6D6',
    },
    brand: {
      purple: '#7f50ba',
      red: '#a53c35',
    },
    bg: '#2d2e3e',
  },
}

const theme = extendTheme({ config, colors })

export default theme
