import React, { useContext } from 'react'
import { GET_FELLOWSHIP_IMCL_LIST } from './gql_imcl'
import { useQuery } from '@apollo/client'
import { ChurchContext } from 'context/ChurchContext'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import ApolloWrapper from 'components/ApolloWrapper/ApolloWrapper'

const FellowshipIMCLList = () => {
  const { fellowshipId } = useContext(ChurchContext)
  const { data, loading, error } = useQuery(GET_FELLOWSHIP_IMCL_LIST, {
    variables: {
      id: fellowshipId,
    },
  })
  const { isOpen, onClose, onOpen } = useDisclosure()
  const fellowship = data?.fellowships[0]

  return (
    <ApolloWrapper apolloResponse={{ data, loading, error }}>
      <Container>
        <Heading>
          {fellowship?.name} {fellowship?.__typename}
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {fellowship?.imcls.map((imcl: any) => (
            <GridItem>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Modal Title</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>Why did they miss church</ModalBody>

                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                    <Button variant="ghost">Secondary Action</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Card key={imcl.id}>
                <CardHeader>
                  {imcl.firstName} {imcl.lastName}
                </CardHeader>
                <CardBody></CardBody>
                <CardFooter>
                  <ButtonGroup spacing="2">
                    <Button variant="solid" colorScheme="blue" onClick={onOpen}>
                      Submit Reason
                    </Button>
                  </ButtonGroup>
                </CardFooter>
              </Card>
            </GridItem>
          ))}
        </Grid>
      </Container>
    </ApolloWrapper>
  )
}

export default FellowshipIMCLList
