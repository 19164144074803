import { RouteArrayType } from '../../types/global-types'
import FellowshipAttendanceReport from './report/FellowshipAttendanceReport'
import FellowshipAttendanceTicker from './ticker/FellowshipAttendanceTicker'
import FellowshipServicesList from './FellowshipServicesList'
import BacentaServicesList from './BacentaServicesList'
import BussingAttendanceReport from './report/BacentaAttendanceReport'
import BussingAttendanceTicker from './ticker/BacentaAttendanceTicker'

export const attendance: RouteArrayType = [
  { path: '/bacenta/bussing-list', element: BacentaServicesList },
  {
    path: '/bacenta/tick-bussing-attendance',
    element: BussingAttendanceTicker,
  },
  {
    path: '/bacenta/bussing-attendance-report',
    element: BussingAttendanceReport,
  },
  { path: '/bacenta/services-list', element: FellowshipServicesList },
  {
    path: '/bacenta/tick-attendance',
    element: FellowshipAttendanceTicker,
  },
  {
    path: '/bacenta/attendance-report',
    element: FellowshipAttendanceReport,
  },
]
