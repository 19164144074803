import { Box, Center, VStack } from '@chakra-ui/react'
import Login from './LoginButton'
import LoginImage from './Logo'

const LoginPage = () => {
  return (
    <Center height="80vh">
      <VStack>
        <Box>
          <LoginImage />
        </Box>
        <Box>
          <Login />
        </Box>
      </VStack>
    </Center>
  )
}

export default LoginPage
