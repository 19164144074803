import { useAuth0 } from '@auth0/auth0-react'
import PageNotFound from 'pages/PageNotFound'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Role } from 'types/global-types'
import LoggingYouIn from './LoggingYouIn'
import useAuth from './useAuth'

type ProtectedRouteProps = {
  roles: Role[]
  placeholder?: boolean
  children: JSX.Element
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth0()
  const { isAuthorised } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      setTimeout(() => navigate('/'), 2000)
    }
  }, [isAuthenticated, navigate])

  if ((isAuthenticated && isAuthorised(props.roles)) || props.placeholder) {
    return <>{props.children}</>
  } else if (isAuthenticated && !isAuthorised(props.roles)) {
    return <PageNotFound />
  } else {
    return <LoggingYouIn />
  }
}

export default ProtectedRoute
