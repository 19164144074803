import { useAuth0 } from '@auth0/auth0-react'
import { Button, Progress } from '@chakra-ui/react'
import useCustomColor from 'hooks/useCustomColor'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { brand } = useCustomColor()

  let navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => navigate('/profile-choose-page'), 500)
    }
  }, [isAuthenticated, navigate])

  if (!isAuthenticated) {
    return (
      <Button
        background={brand}
        color="white"
        size="lg"
        width="336.92px"
        height="60px"
        fontSize="24px"
        marginTop={10}
        onClick={() => loginWithRedirect()}
      >
        Login
      </Button>
    )
  } else {
    return (
      <>
        <Progress marginY={5} size="xs" isIndeterminate />
        <Button
          background={brand}
          size="lg"
          width="336.92px"
          height="60px"
          //Loading Stuff
          disabled
        >
          Looging You In...
        </Button>
      </>
    )
  }
}

export default LoginButton
