import { MutationFunction } from '@apollo/client'
import { SearchIcon } from '@chakra-ui/icons'
import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import CheckboxGroup from 'components/formik/MemberCheckboxGroup'
import { Form, Formik, FormikHelpers } from 'formik'
import { getHumanReadableDate } from 'jd-date-utils'
import { useNavigate } from 'react-router'
import { BussingRecord, ServiceRecord } from 'types/global-types'
import { ChurchForAttendance } from '../attendance-types'

type FormOptions = {
  sheep: string[]
  deer: string[]
  goats: string[]
}

const AttendanceTicker = ({
  church,
  service,
  RecordMemberAttendance,
}: {
  church: ChurchForAttendance
  service: ServiceRecord | BussingRecord
  RecordMemberAttendance: MutationFunction
}) => {
  const navigate = useNavigate()

  const initialValues: FormOptions = {
    sheep: [],
    deer: [],
    goats: [],
  }

  const toast = useToast()
  const onSubmit = async (
    values: FormOptions,
    onSubmitProps: FormikHelpers<FormOptions>
  ) => {
    onSubmitProps.setSubmitting(true)
    const combinedMembership = [...sheep, ...deer, ...goats].map(
      (member) => member.value
    )
    const combinedPresent = [...values.sheep, ...values.deer, ...values.goats]
    const combinedAbsent = combinedMembership.filter(
      (member) => !combinedPresent.includes(member)
    )

    try {
      await RecordMemberAttendance({
        variables: {
          bacentaId: church.id,
          recordId: service.id,
          presentMembers: combinedPresent,
          absentMembers: combinedAbsent,
        },
      })

      toast({
        title: 'Attendance Submitted',
        description: 'Attendance has been submitted successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      if (service.__typename === 'BussingRecord') {
        navigate(
          `/${church.__typename.toLowerCase()}/bussing-attendance-report`
        )
      } else {
        navigate(`/${church.__typename.toLowerCase()}/attendance-report`)
      }
    } catch (error) {
      console.error((error as Error).message)
      const err = error as Error
      toast({
        title: 'Error Submitting Attendance',
        description: err.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } finally {
      onSubmitProps.setSubmitting(false)
    }
  }

  const sheep =
    church?.sheep.map((sheep) => ({
      key: sheep.firstName + ' ' + sheep.lastName,
      pictureUrl: sheep.pictureUrl,
      value: sheep.id,
    })) ?? []

  const goats =
    church?.goats.map((goat) => ({
      key: goat.firstName + ' ' + goat.lastName,
      pictureUrl: goat.pictureUrl,
      value: goat.id,
    })) ?? []

  const deer =
    church?.deer.map((deer) => ({
      key: deer.firstName + ' ' + deer.lastName,
      pictureUrl: deer.pictureUrl,
      value: deer.id,
    })) ?? []

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount>
      {(formik) => (
        <Form>
          <Container marginTop={10} centerContent>
            <Heading>{`${church.__typename} Attendance`}</Heading>
            <Text marginBottom={4}>
              {getHumanReadableDate(service.serviceDate.date.toString(), true)}
            </Text>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon />}
              />
              <Input placeholder="Search" />
            </InputGroup>
            <VStack marginTop={10} align="stretch" w={'full'}>
              <Image src={service?.membersPicture}></Image>
              {church?.sheep.length && <Heading>Sheep</Heading>}
              <CheckboxGroup name="sheep" options={sheep} />

              {church?.goats.length && <Heading>Goat</Heading>}
              <CheckboxGroup name="goats" options={goats} />

              {church?.deer.length && <Heading>Deer</Heading>}
              <CheckboxGroup name="deer" options={deer} />
            </VStack>
          </Container>
          <Flex width="100%">
            <Button
              size={'lg'}
              height={94}
              width="100%"
              position="fixed"
              bottom={0}
              zIndex={3}
              colorScheme="green"
              isLoading={formik.isSubmitting}
              loadingText="Submitting"
              rounded={0}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default AttendanceTicker
