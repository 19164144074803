import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './login/LoginPage'
import { ChurchContextProvider } from './context/ChurchContext'
import { UserContextProvider } from './context/UserContext'
import { directory } from './pages/directory/directoryRoutes'
import ProtectedRoute from './auth/ProtectedRoute'
import Navigation from './components/Navigation'
import { attendance } from './pages/attendance/attendanceRoutes'
import PageNotFound from 'pages/PageNotFound'
import { ModalContextProvider } from 'context/ModalContext'
import { imcl } from 'pages/imcl/imclRoutes'

export default function App(): JSX.Element {
  return (
    <ChurchContextProvider>
      <UserContextProvider>
        <ModalContextProvider>
          <Navigation />
          <Routes>
            {[...directory, ...attendance, ...imcl].map((route, i) => (
              <Route
                key={i}
                path={route.path}
                element={
                  <ProtectedRoute
                    roles={route.roles ?? ['all']}
                    placeholder={route.placeholder}
                  >
                    <route.element />
                  </ProtectedRoute>
                }
              />
            ))}
            <Route path="/" element={<Dashboard />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ModalContextProvider>
      </UserContextProvider>
    </ChurchContextProvider>
  )
}
