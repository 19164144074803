import { createContext, useState } from 'react'
import { ContextProviderProps } from './context.types'

interface ModalInterface {
  title: string
  body: string
  isOpen: boolean
  onClose: () => void
}

interface ModalContextInterface {
  modalContent: ModalInterface
  setModalContent: React.Dispatch<any>
}

const initialModalContent: ModalInterface = {
  title: '',
  body: '',
  isOpen: false,
  onClose: () => null,
}

export const ModalContext = createContext<ModalContextInterface>({
  modalContent: initialModalContent,
  setModalContent: (state: ModalContextInterface) => {},
})

export const ModalContextProvider = ({ children }: ContextProviderProps) => {
  const [modalContent, setModalContent] =
    useState<ModalInterface>(initialModalContent)

  return (
    <ModalContext.Provider value={{ modalContent, setModalContent }}>
      {children}
    </ModalContext.Provider>
  )
}
