import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import ApolloWrapper from '../../../components/ApolloWrapper/ApolloWrapper'
import { ChurchContext } from '../../../context/ChurchContext'
import { GET_GOVERNORSHIP_MEMBERS } from './memberList.gql'
import MemberList from './MemberList'

const GovernorshipMembers = () => {
  const { governorshipId } = useContext(ChurchContext)
  const apollo = useQuery(GET_GOVERNORSHIP_MEMBERS, {
    variables: { id: governorshipId },
  })

  const governorship = apollo.data?.governorships[0]

  return (
    <ApolloWrapper apolloResponse={apollo}>
      <MemberList church={governorship} />
    </ApolloWrapper>
  )
}

export default GovernorshipMembers
