import whitelogo from '../assets/img/flclogo.png'
import { Image, Text, Container } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import useCustomColor from 'hooks/useCustomColor'

const HeaderText = () => {
  const { user } = useAuth0()
  const { brand, text } = useCustomColor()

  return (
    <Container marginTop="5" marginBottom={10} centerContent>
      <Image style={{ height: '50px' }} src={whitelogo} alt="First Love Logo" />

      <Text fontSize="xl" fontWeight={'semibold'}>
        <Text as="span" color={brand}>
          Welcome{' '}
        </Text>
        {user?.given_name}!
      </Text>

      <Text color={text.secondary}>
        Which profile would you like to access?
      </Text>
    </Container>
  )
}

export default HeaderText
