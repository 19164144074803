import { GridItem, Avatar, Text } from '@chakra-ui/react'
import useCustomColor from '../hooks/useCustomColor'

interface ButtonProps {
  src: string
  roleName: string
  roleLocation: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const GridButton = (props: ButtonProps) => {
  const { bg } = useCustomColor()

  return (
    <GridItem
      borderRadius="10px"
      display="flex"
      alignItems="center"
      padding="10px"
      cursor="pointer"
      flexDirection="column"
      justifyContent="space-between"
      w="95%"
      h="160"
      bg={bg}
      onClick={props.onClick}
    >
      <Text fontSize="xs">{props.roleName}</Text>
      <Avatar name={props.roleLocation} src={props.src} size="lg" />
      <Text fontSize="xs" style={{ textAlign: 'center' }}>
        {props.roleLocation}
      </Text>
    </GridItem>
  )
}

export default GridButton
